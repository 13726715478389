// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

require("@rails/ujs").start()
require("turbolinks").start()
require("@rails/activestorage").start()
require("channels")

// ActionText
require("trix")
require("@rails/actiontext")

// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)

import "@fortawesome/fontawesome-free/css/all.css";

import 'popper.js'
import 'bootstrap'
import '../src/application.scss'
import '../src/actiontext.scss'

import $ from 'jquery'

// Load i18n
import I18n from '../src/i18n.js.erb'
window.I18n = I18n

// Add DataTables jQuery plugin
require('imports-loader?define=>false!datatables.net')(window, $)
require('imports-loader?define=>false!datatables.net-bs4')(window, $)
require('imports-loader?define=>false!datatables.net-select')(window, $)
require('imports-loader?define=>false!datatables.net-colreorder')(window, $)
require('imports-loader?define=>false!datatables.net-buttons')(window, $)
require('imports-loader?define=>false!datatables.net-buttons/js/buttons.colVis')(window, $)
require('imports-loader?define=>false!datatables.net-buttons-bs4/js/buttons.bootstrap4')(window, $)

// Load datatables styles
import 'datatables.net-bs4/css/dataTables.bootstrap4.css'
import 'datatables.net-select-bs4/css/select.bootstrap4.css'
import 'datatables.net-colreorder-bs4/css/colReorder.bootstrap4.css'
import 'datatables.net-buttons-bs4/css/buttons.bootstrap4.css'

function getSelectedRegistrationIds(datatable) {
    return getRegistrationIds(datatable, {selected: true});
}

function getRegistrationIds(datatable, selector) {
    let ids = [];

    datatable.rows(selector).every (function (rowIdx, tableLoop, rowLoop) {
        let id = this.node().getAttribute('data-registration-id');

        if (ids.indexOf(id) == -1) {
            ids.push(id);
        }
    });

    return ids;
}

function forRowsWithRegistrationId(datatable, selector, ids, callback) {
    datatable.rows(selector).every (function (rowIdx, tableLoop, rowLoop) {
        let id = this.node().getAttribute('data-registration-id');

        if (ids.indexOf(id) >= 0) {
            callback.call(this);
        }
    });
}

function massUpdate(transition, ids) {
    const update_url = $('#participants').attr('data-update-url');

    $.ajax(update_url, {
        contentType : 'application/json',
        beforeSend: function(xhr) {xhr.setRequestHeader('X-CSRF-Token', $('meta[name="csrf-token"]').attr('content'))},
        type : 'POST',
        data:  JSON.stringify({transition: transition, id: ids}),
        success: function(e) {
            location.reload();
        }
    });
}

$(document).on('ready turbolinks:load', function() {
    const participantsTable =  $('#participants').DataTable({
         "dom": "<'row mb-3'B><'row'<'col-sm-6 col-md-3'l><'col-sm-6 col-md-3'f><'col-sm-12 col-md-6'p>>" +
                "<'row'<'col-sm-12'tr>>" +
            "<'row'<'col-sm-12 col-md-5'i><'col-sm-12 col-md-7'p>>",
        "colReorder": "true",
        "pagingType": "full_numbers",
        "stateSave": true,
        "stateDuration": 0,
        columnDefs: [ {
            orderable: false,
            className: 'select-checkbox',
            targets:  0,
        }],
        select: {
            style: 'multi',
            // selector: 'td:first-child'
        },
        buttons: [
            {
                extend: 'selected',
                text: 'bestätigen',
                action: function ( e, dt, button, config ) {
                    massUpdate('confirm', getSelectedRegistrationIds(dt));
                }
            },
            {
                extend: 'selected',
                text: 'ablehnen',
                action: function ( e, dt, button, config ) {
                    massUpdate('reject', getSelectedRegistrationIds(dt))
                }
            },
            {
                extend: 'selected',
                text: 'auf Warteliste',
                action: function ( e, dt, button, config ) {
                    massUpdate('wait', getSelectedRegistrationIds(dt))
                }
            },
            {
                extend: 'selected',
                text: 'dienstlich',
                action: function ( e, dt, button, config ) {
                    massUpdate('serve', getSelectedRegistrationIds(dt))
                }
            },
            {
                extend: 'selected',
                text: 'abmelden',
                action: function ( e, dt, button, config ) {
                    massUpdate('cancel', getSelectedRegistrationIds(dt))
                }
            },
            {
                extend: 'selected',
                text: 'ignorieren',
                action: function ( e, dt, button, config ) {
                    massUpdate('ignore', getSelectedRegistrationIds(dt))
                }
            },
        ],
        language: {
            processing: I18n.t('datatable.processing'),
            search: I18n.t('datatable.search'),
            lengthMenu: I18n.t('datatable.lengthMenu'),
            info: I18n.t('datatable.info'),
            infoEmpty: I18n.t('datatable.infoEmpty'),
            infoFiltered: I18n.t('datatable.infoFiltered'),
            infoPostFix: I18n.t('datatable.infoPostFix'),
            loadingRecords: I18n.t('datatable.loadingRecords'),
            zeroRecords: I18n.t('datatable.zeroRecords'),
            emptyTable: I18n.t('datatable.emptyTable'),
            paginate: {
                first: I18n.t('datatable.paginate.first'),
                previous: I18n.t('datatable.paginate.previous'),
                next: I18n.t('datatable.paginate.next'),
                last: I18n.t('datatable.paginate.last'),
            },
            aria: {
                sortAscending: I18n.t('datatable.aria.sortAscending'),
                sortDescending: I18n.t('datatable.aria.sortDescending'),
            },
            buttons: {
                print: I18n.t('datatable.buttons.print'),
                colvis: I18n.t('datatable.buttons.colvis'),
                copy: I18n.t('datatable.buttons.copy'),
                copyTitle: I18n.t('datatable.buttons.copyTitle'),
                copyKeys: I18n.t('datatable.buttons.copyKeys'),
                copySuccess: {
                    "_": I18n.t('datatable.buttons.copySuccess._'),
                    "1": I18n.t('datatable.buttons.copySuccess.1'),
                },
                pageLength: {
                    "-1": I18n.t('datatable.buttons.pageLength.-1'),
                    "_": I18n.t('datatable.buttons.pageLength._'),
                }
            }
        }
    });

    let preventSelectCallback = false;

    participantsTable.on('select.dt', function (e, dt, type, indexes) {
        if (preventSelectCallback) return;
        preventSelectCallback = true;

        if (type === 'row') {
            const ids = getRegistrationIds(dt, indexes);

            forRowsWithRegistrationId(dt, {selected: false}, ids, function () {
                this.select();
            });
        }

        preventSelectCallback = false;
    });

    participantsTable.on('deselect.dt', function (e, dt, type, indexes) {
        if (preventSelectCallback) return;
        preventSelectCallback = true;

        if (type === 'row') {
            const ids = getRegistrationIds(dt, indexes);
            forRowsWithRegistrationId(dt, {selected: true}, ids, function () {
                this.deselect();
            });
        }

        preventSelectCallback = false;
    });

    document.addEventListener('turbolinks:before-cache', function() {
        $('.additional-button').appendTo('#additional-buttons');

        if (participantsTable != null) {
            participantsTable.rows().deselect();
            participantsTable.destroy();
        }
    });

    $(document).on('click', '.click-filter', function(e) {
        const filter = this.getAttribute('data-filter');

        if (participantsTable != null) {
            if (participantsTable.search() == filter) {
                participantsTable.search('').draw();
            } else {
                participantsTable.search(filter).draw();
            }
        }

        e.preventDefault();
    });

    function drawFilter() {
        if (participantsTable != null) {
            const search = participantsTable.search();

            $('.click-filter').each(function () {
                $(this).toggleClass('active', this.getAttribute('data-filter') == search);
            });
        }
    }

    $('#participants').on( 'draw.dt', drawFilter);
    $('.additional-button').appendTo('.dt-buttons');


    drawFilter();

    $('#tenant_welcome_message').toggleClass('form-control', false);
    $('#tenant_imprint').toggleClass('form-control', false);
    $('#tenant_privacy_policy').toggleClass('form-control', false);

    $(document).on('click', '.remove-button', function(e) {
        const destroyField = $(this).siblings('input.remove'),
            removable=$(this).parents('.removable'),
            header=removable.find('.removable-header'),
            body=removable.find('.removable-body');

        destroyField.val(true);
        body.hide();
        header.css('text-decoration', 'line-through');
        $(this).hide();

        e.preventDefault();
    });

    $('[data-toggle="tooltip"]').tooltip();

    $(document).on('click', '.prompt-password', function(e) {
        const $form = $(this).siblings('div.password-form');

        $(this).hide();
        $form.show();
        $form.find('.password-field').focus();

        e.preventDefault();
    });
});